/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
	let handle;

	return () =>
	{
		if (handle)
		{
			clearTimeout(handle);
		}

		handle = setTimeout(callback, delay);
	};
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
	constructor(message)
	{
		super(message);

		this.name = 'SocketTimeoutError';

		if (Error.hasOwnProperty('captureStackTrace')) // Just in V8.
			Error.captureStackTrace(this, SocketTimeoutError);
		else
			this.stack = (new Error(message)).stack;
	}
}

export const countSlashes = () =>
{
	return window.location.pathname
		.match(/\//g)
		.length;
};

export const strToByte = (string) =>
{
	const size = string.toString().toLowerCase();
	const [ value, factor ] = size.match(/([0-9]+)|(b|kb|mb|gb)/gi);

	switch (factor)
	{
		case 'kb': return value * 1024;
		case 'mb': return value * 1024 * 1024;
		case 'gb': return value * 1024 * 1024 * 1024;
		default: return parseInt(value);
	}
};